import firebase from 'firebase';

const config = {
  apiKey: 'AIzaSyDatkFisxi2V8faGhHokgv3KaeJg2xbBTU',
  authDomain: 'mybook-jaques.firebaseapp.com',
  projectId: 'mybook-jaques',
  storageBucket: 'mybook-jaques.appspot.com',
  messagingSenderId: '988994226885',
  appId: '1:988994226885:web:54da066736e6065e3fbeac',
  measurementId: 'G-E4LEBTYQR0',
};

firebase.initializeApp( config );

export const auth = firebase.auth();
export const db = firebase.firestore();
export const storage = firebase.storage();
export const timeStamp = firebase.firestore.Timestamp;
export type TimeStamp = firebase.firestore.Timestamp;
export const fieldValue = firebase.firestore.FieldValue;
export type FieldValue = firebase.firestore.FieldValue;
export const authPersistenceLocal = firebase.auth.Auth.Persistence.LOCAL;
export const authPersistenceSession = firebase.auth.Auth.Persistence.SESSION;
export const emailAuthProvider = firebase.auth.EmailAuthProvider;
